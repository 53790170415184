import { setLoadingDone, setLoadingProgress } from 'actions/loading'
import { setOtpSecret } from 'actions/otpSecret'
import axios from 'axios'

const correctParams = config => {
  const { data, method, needCorrectParams = true, ...rest } = config
  if (data && needCorrectParams) {
    if (!method || ['get', 'delete'].includes(method.toLowerCase())) {
      return {
        ...rest,
        method,
        params: data,
      }
    }
  }

  return config
}

class BaseApi {
  // Promise based HTTP client
  // https://github.com/axios/axios
  create = options => {
    const instance = axios.create({
      baseURL: '/',
      timeout: 120000, // milliseconds
      responseType: 'json',
      method: 'get',
      ...options,
    })
    // https://github.com/axios/axios#axiosconfig
    return input => async (dispatch, getState) => {
      const { loadingTarget } = input || {}
      try {
        if (loadingTarget) {
          dispatch(setLoadingProgress({ taget: loadingTarget }))
        }

        const extras = {}
        const state = getState()
        if (state?.user?.token) {
          extras.headers = {
            ...input?.headers,
            Authorization: `bearer ${state.user.token}`,
          }
        }

        const rslt = await instance({ ...correctParams(input), ...extras })

        // 目前在 gpi/v1/useraddrs/countries 中給予
        const otpSecret = rslt?.headers['otp-secret']
        const otpIv = rslt?.headers['otp-iv']
        if (otpSecret && otpIv) {
          dispatch(setOtpSecret({ otpSecret, otpIv }))
        }

        const data = this.checkResponse(rslt)
        return data
      } catch (error) {
        const {
          response: { headers, status, data },
        } = error
        if (status !== 200) {
          const contentType = headers['content-type']
          if (contentType?.includes('json')) {
            data.errStatus = status
            throw data
          }
        }
        throw error
      } finally {
        if (loadingTarget) {
          dispatch(setLoadingDone({ taget: loadingTarget }))
        }
      }
    }
  }

  export = options => {
    const instance = axios.create({
      baseURL: '/',
      timeout: 120000, // milliseconds
      responseType: 'blob',
      method: 'get',
      ...options,
    })
    // https://github.com/axios/axios#axiosconfig
    return input => async (dispatch, getState) => {
      const { loadingTarget, defaultFilename = 'file.csv' } = input || {}
      try {
        if (loadingTarget) {
          dispatch(setLoadingProgress({ taget: loadingTarget }))
        }

        const extras = {}
        const state = getState()
        if (state?.user?.token) {
          extras.headers = { Authorization: `bearer ${state.user.token}` }
        }

        const rslt = await instance({ ...correctParams(input), ...extras })
        const { headers } = rslt
        const contetnDisposition = headers['content-disposition'] || ''
        const dispositionList = contetnDisposition.split(';')
        const utf8Filename = dispositionList
          .find(s => s.includes("filename*=utf-8''"))
          ?.replace("filename*=utf-8''", '')
          .trim()
        const normalilename = dispositionList
          .find(s => s.includes('filename='))
          ?.replace('filename=', '')
          .trim()
        const filename =
          (utf8Filename && decodeURIComponent(utf8Filename)) ||
          normalilename ||
          defaultFilename

        const data = this.checkResponse(rslt)
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))

        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)

        document.body.appendChild(link)

        link.click()

        link.remove()
        // return data
      } catch (error) {
        const {
          response: { headers, status, data },
        } = error
        if (status !== 200) {
          const contentType = headers['content-type']
          if (contentType?.includes('json')) {
            throw data
          }
        }
        throw error
      } finally {
        if (loadingTarget) {
          dispatch(setLoadingDone({ taget: loadingTarget }))
        }
      }
    }
  }

  getFile = options => {
    const instance = axios.create({
      baseURL: '/',
      timeout: 120000, // milliseconds
      responseType: 'blob',
      method: 'get',
      ...options,
    })
    // https://github.com/axios/axios#axiosconfig
    return input => async (dispatch, getState) => {
      const { loadingTarget } = input || {}
      try {
        if (loadingTarget) {
          dispatch(setLoadingProgress({ taget: loadingTarget }))
        }

        const extras = {}
        const state = getState()
        if (state?.user?.token) {
          extras.headers = { Authorization: `bearer ${state.user.token}` }
        }
        const rslt = await instance({ ...correctParams(input), ...extras })
        const { headers } = rslt
        const contentType = headers['content-type']
        const ext = contentType?.split('/')?.[1] || ''
        const isPdf = ext?.includes('pdf')
        const configs = isPdf ? { type: 'application/pdf' } : {}
        const data = this.checkResponse(rslt)
        const dataUrl = window.URL.createObjectURL(new Blob([data], configs))

        return { dataUrl, ext }
      } catch (error) {
        const {
          response: { headers, status, data },
        } = error
        if (status !== 200) {
          const contentType = headers['content-type']
          if (contentType?.includes('json')) {
            throw data
          }
        }
        throw error
      } finally {
        if (loadingTarget) {
          dispatch(setLoadingDone({ taget: loadingTarget }))
        }
      }
    }
  }

  checkResponse = response => {
    if (
      response.data &&
      response.data.code > 0 &&
      response.data.message?.startswith('ERR_')
    ) {
      const error = new Error(response.data.message)
      error.response = response
      throw error
    }
    return response.data
  }
}

export default BaseApi
